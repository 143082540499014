import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

export default ({ country, config }) => {
  const isMyOrdersFSMEnabled =
    config.FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_MY_ORDERS?.includes(country)

  const isMyProfileFSMEnabled =
    config.FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_MY_PROFILE?.includes(country)
  const isMyTradeinFSMEnabled =
    config.FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_MY_TRADEIN?.includes(country)

  return [
    // Client Dashboard.
    {
      path: 'dashboard',
      name: ROUTES.DASHBOARD.SELF,
      component: () => dynamicImport(import('./Dashboard.vue')),
      redirect: (to) => ({ ...to, name: ROUTES.DASHBOARD.MY_ORDERS.SELF }),
      children: [
        {
          path: 'orders',
          name: ROUTES.DASHBOARD.MY_ORDERS.SELF,
          component: () => dynamicImport(import('@my-orders/Orders')),
          meta: {
            disableClientSideNavigation: isMyOrdersFSMEnabled,
          },
        },
        {
          path: 'sales',
          name: ROUTES.DASHBOARD.MY_SALES,
          component: () =>
            dynamicImport(import('@buyback/customer/dashboard/pages/MySales')),
          meta: {
            disableClientSideNavigation: isMyTradeinFSMEnabled,
          },
        },
        {
          path: 'orders/:orderId/orderlines/:orderlineId/cancel',
          name: ROUTES.DASHBOARD.MY_ORDERS.CANCEL,
          component: () =>
            dynamicImport(import('@my-orders/components/CancelOrder')),
          props: true,
        },
        {
          path: 'orders/:orderId/orderlines/:orderlineId/withdraw',
          name: ROUTES.DASHBOARD.MY_ORDERS.WITHDRAW.ORDER,
          component: () =>
            dynamicImport(
              import(
                '@my-orders/components/Withdraw/ConnectedOrderWithdraw.vue'
              ),
            ),
          props: true,
        },
        {
          path: 'orders/:orderId/orderlines/:orderlineId/insurances/withdraw',
          name: ROUTES.DASHBOARD.MY_ORDERS.WITHDRAW.INSURANCES,
          component: () =>
            dynamicImport(
              import(
                '@my-orders/components/Withdraw/ConnectedInsuranceWithdraw.vue'
              ),
            ),
          props: true,
        },
        {
          path: 'past-orders',
          name: ROUTES.DASHBOARD.MY_PETANQUE_ORDERS,
          component: () =>
            dynamicImport(
              import('@my-petanque-orders/pages/PetanqueOrdersPage.vue'),
            ),
        },
        {
          path: 'profile',
          name: ROUTES.DASHBOARD.KYC.PROFILE,
          component: () =>
            dynamicImport(import('@kyc/Profile/ConnectedProfile.vue')),
          meta: {
            disableClientSideNavigation: isMyProfileFSMEnabled,
          },
        },
        {
          path: 'refer-friend',
          name: ROUTES.DASHBOARD.KYC.REFER_FRIEND,
          component: () =>
            dynamicImport(import('@kyc/ReferFriend/Landing.vue')),
        },
        {
          path: 'others',
          name: ROUTES.DASHBOARD.KYC.OTHER,
          meta: { disableClientSideNavigation: true },
        },
        {
          path: 'favorites',
          component: () =>
            dynamicImport(import('@my-favorites/MyFavorites.vue')),
          name: ROUTES.DASHBOARD.FAVORITES,
        },
      ],
      meta: {
        auth: { required: true },
        disableClientSideNavigation: isMyOrdersFSMEnabled,
      },
    },
    {
      name: ROUTES.REFERRAL_PROGRAM_SHARING_ROUTE,
      path: 'refer-friend-welcome',
      meta: { disableClientSideNavigation: true },
    },
    {
      name: ROUTES.DASHBOARD.KYC.DELETE_ACCOUNT,
      path: 'dashboard/profile/delete-account',
      meta: { disableClientSideNavigation: true },
    },
  ]
}
