import { COUNTRIES } from '@config/constants'
import { dynamicImport, insertIf } from '@core/helpers'
import { ROUTES } from '@router'

const onboardingRoute = [
  {
    path: '/terms/merchant/onboarding',
    name: ROUTES.PDF.TERMS_MERCHANT_ONBOARDING,
    meta: { disableClientSideNavigation: true },
  },
]
const hasOnboardingRoute = (country) =>
  [COUNTRIES.FR, COUNTRIES.GB, COUNTRIES.US].includes(country)

export default ({ country }) => {
  return [
    // [BestPractice] The following `/terms pages is managed outside from our codebase.
    // It's a static file uploaded to S3 and proxied by Cloudflare.
    {
      path: 'terms/refer-friend',
      name: ROUTES.LEGAL.REFER_FRIEND,
      meta: { disableClientSideNavigation: true },
    },
    {
      path: 'get-my-visitor-id',
      name: ROUTES.LEGAL.GET_VISITOR_ID,
      component: () => dynamicImport(import('./pages/get-my-visitor-id.vue')),
    },
    {
      path: 'cookies',
      name: ROUTES.LEGAL.COOKIES,
      component: () => dynamicImport(import('./pages/cookies.vue')),
    },
    // [ACQ-1482]
    // Note for the front-apps migration: consider creating a new layout without cookie modal for these routes
    // We don't need SEO for these pages, so metadata in the head are not mandatory
    // The logic to duplicate from Landing.vue is the data fetching
    {
      path: 'legal/previous/:pageName',
      component: () => dynamicImport(import('@cms/Landing.vue')),
      children: [
        {
          path: '',
          component: () => dynamicImport(import('./pages/LegalTemplate')),
          meta: {
            noCookieModal: true,
            disableClientSideNavigation: true,
          },
          name: ROUTES.CMS.LEGAL_PREVIOUS_VERSION,
        },
      ],
    },
    {
      path: 'legal/:pageName',
      component: () => dynamicImport(import('@cms/Landing.vue')),
      children: [
        {
          path: '',
          component: () => dynamicImport(import('./pages/LegalTemplate')),
          meta: {
            noCookieModal: true,
            disableClientSideNavigation: true,
          },
          name: ROUTES.CMS.LEGAL_PAGE,
        },
      ],
    },
    ...insertIf(hasOnboardingRoute(country), onboardingRoute),
  ]
}
