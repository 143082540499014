import isEmpty from 'lodash/isEmpty'

import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

function preserveOriginQuery(to, from, next) {
  if (!isEmpty(from.query) && isEmpty(to.query)) {
    next({ ...to, query: from.query })
  } else next()
}

export default ({ country, config }) => {
  const isFrontAppsRouteResetPasswordOpenedInCountry =
    config.FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_RESET_PASSWORD.includes(
      country,
    )

  const isFrontAppsRouteSetNewPasswordOpenedInCountry =
    config.FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_SET_NEW_PASSWORD.includes(
      country,
    )

  return [
    {
      name: ROUTES.AUTH.REGISTER,
      path: 'register',
      component: () => dynamicImport(import('./pages/OnBoarding')),
      meta: {
        guest: {
          required: true,
          redirection: { name: ROUTES.DASHBOARD.SELF },
        },
      },
    },
    {
      name: ROUTES.AUTH.VERIFICATION_EMAIL,
      path: 'verification-email',
      component: () => dynamicImport(import('./pages/VerificationEmail')),
    },
    {
      name: ROUTES.AUTH.PASSWORD_RESET.MAIN,
      path: 'password-reset',
      beforeEnter: preserveOriginQuery,
      component: () =>
        dynamicImport(import('./pages/PasswordReset/ForgotPassword')),
      meta: {
        disableClientSideNavigation:
          isFrontAppsRouteResetPasswordOpenedInCountry,
      },
    },
    {
      name: ROUTES.AUTH.PASSWORD_RESET.CONFIRM,
      path: 'password-reset/confirm',
      beforeEnter: preserveOriginQuery,
      component: () =>
        dynamicImport(import('./pages/PasswordReset/RequestSent')),
      meta: {
        disableClientSideNavigation:
          isFrontAppsRouteResetPasswordOpenedInCountry,
      },
    },
    {
      name: ROUTES.AUTH.PASSWORD_RESET.NEW,
      path: 'password-reset/set-new/:userId/:token',
      component: () =>
        dynamicImport(import('./pages/PasswordReset/SetNewPassword')),
      meta: {
        disableClientSideNavigation:
          isFrontAppsRouteSetNewPasswordOpenedInCountry,
      },
    },
    {
      name: ROUTES.AUTH.PASSWORD_RESET.EXPIRED,
      path: 'password-reset/expired-reset',
      component: () =>
        dynamicImport(import('./pages/PasswordReset/RequestExpired')),
    },
  ]
}
